import { useNavigation } from "@remix-run/react";
import jump from "~/assets/dog-animations/1_cropped.gif";
import highfive from "~/assets/dog-animations/2_cropped.gif";
import walking from "~/assets/dog-animations/running-dog_cropped.gif";
import speak from "~/assets/dog-animations/speech_cropped.gif";

const animations = {
  jump,
  highfive,
  walking,
  speak,
};

export function RunningDog({
  animation,
}: {
  animation: keyof typeof animations;
}) {
  return (
    <img
      className="w-16"
      src={animations[animation]}
      alt="A cartoon dog walking in an endless loop"
    />
  );
}

export function RunningDogWithLoadingTitle() {
  const navigation = useNavigation();

  return (
    <div className="flex h-full flex-col items-center justify-center gap-2">
      <RunningDog animation="walking" />
      <h2 className="text-2xl font-bold text-slate-700">
        {navigation.state !== "idle" ? "Loading..." : "No animal selected"}
      </h2>
    </div>
  );
}
